<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
        >
          {{ $t("view.permissions") }}
        </h4>

        <div v-if="permissionLoading" class="text-center">
          <span
            class="spinner-grow spinner-grow-sm mr-1 text-center"
            role="status"
            aria-hidden="true"
          >
          </span>
        </div>

        <div v-if="!permissionLoading">
          <div
            class="form-group col-10"
            v-for="(permission, index) in permissions"
            :key="index"
          >
            <label
              class="mb-3 mt-3 control-label badge rounded-pill badge-md badge-soft-primary"
              style="padding: 10px"
            >
              <span
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                "
              >
                {{ findText(index) }}
              </span>
            </label>

            <div class="row col-12 text-center" style="margin-left: 50px">
              <div
                v-for="(item, idx) in permission.length"
                :key="idx"
                class="custom-control custom-switch col-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  @change="isChecked($event, permission[idx].id)"
                  :id="permission[idx].id"
                  :value="permission[idx].id"
                  :checked="
                    row.permissions_ids.indexOf(permission[idx].id) !== -1
                      ? true
                      : ''
                  "
                />
                <label
                  class="custom-control-label"
                  style="font-size: 15px; margin-left: -40px"
                  :for="permission[idx].id"
                >
                  <span>
                    <!-- v-if="(locale == 'ar')" -->
                    <span
                      v-if="permission[idx].name == 'view'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.view") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'viewRow'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.viewRow") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'add'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.add") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'show'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.show") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'edit'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.edit") }}
                    </span>
                    <span
                      v-if="permission[idx].name == 'delete'"
                      :class="
                        $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                      "
                    >
                      {{ $t("role.delete") }}
                    </span>
                  </span>
                  <!-- <span v-else>{{ permission[idx].name }}</span> -->
                </label>
              </div>
            </div>
            <p><br /></p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "FormPermissions",
  props: ["permissions_ids"],
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        permissions_ids: this.$props.permissions_ids,
      },
      permissions: [],
      permissionLoading: false,
    };
  },
  mounted() {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchPermissions();
  },
  methods: {
    // is Checked
    isChecked($event, id) {
      if ($event.target.checked) {
        this.row.permissions_ids.push(id);
      } else {
        this.row.permissions_ids.splice(
          this.row.permissions_ids.indexOf(id),
          1
        );
      }

      this.$emit("permissionChange", this.row.permissions_ids);
    },

    fetchPermissions() {
      this.permissionLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/permissions",
        method: "GET",
        data: {},
        params: {},
      };
      this.axios(options)
        .then((res) => {
          this.permissionLoading = false;
          this.permissions = res.data.data.rows;
        })
        .catch((err) => {
          this.permissionLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    findText(text) {
      if (text == "emergencyconsultations")
        return this.$t("nav.emergencyConsultations");
      if (text == "appointmentconsultations")
        return this.$t("nav.appointmentConsultations");
      if (text == "chatconsultations") return this.$t("nav.chatConsultations");
      if (text == "books") return this.$t("nav.books");
      if (text == "categories") return this.$t("nav.categories");
      if (text == "messages") return this.$t("nav.messages");
      if (text == "splashes") return this.$t("nav.splashes");
      if (text == "faqs") return this.$t("nav.faqs");
      if (text == "lecturers") return this.$t("nav.lecturers");
      if (text == "notifications") return this.$t("nav.notifications");
      if (text == "onlinecourses") return this.$t("nav.onlineCourses");
      if (text == "offlinecourses") return this.$t("nav.offlineCourses");
      if (text == "pages") return this.$t("nav.pages");
      if (text == "roles") return this.$t("nav.roles");
      if (text == "settings") return this.$t("nav.settings");
      if (text == "coupons") return this.$t("nav.coupons");
      if (text == "users") return this.$t("nav.users");
      if (text == "employees") return this.$t("nav.employees");
      if (text == "reports") return this.$t("nav.reports");
      if (text == "videoconsultations")
        return this.$t("nav.videoConsultations");
      if (text == "meetingconventions")
        return this.$t("nav.meetingConventions");
      if (text == "voiceconsultations")
        return this.$t("nav.voiceConsultations");
      if (text == "services") return this.$t("nav.services");
      if (text == "slider") return this.$t("nav.slider");
      if (text == "team") return this.$t("nav.team");
      if (text == "workFields") return this.$t("nav.workFields");
      if (text == "appsettings") return this.$t("nav.appSettings");
      if (text == "contact") return this.$t("nav.contact");
      //
      else return "";
    },
  },
};
</script>
